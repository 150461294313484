

.page {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.menu-section {
  width: 210px;
  border-right: 1px solid #d4d4d4;

}

.content-section {
  flex-grow: 1;
  padding: 40px;
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
}

.taxonSearch {
  width: 100%;
}

.sideBySide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.suggestions {
  flex-wrap: wrap;
  justify-content: start;
}

.suggestions > * {
  cursor: pointer;
}

.sideBySide > div {
  margin-left: 10px;
  margin-right: 10px;
}


.cardContent {
  flex-grow: 1;
}

.cardActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}

.formCard {
  margin-bottom: 10px;
}

.testview {
  display: flex;
  position: relative;
  flex-grow: 1;
}

.testview .MuiDrawer-root * {
  position: relative;
}

.taxonContainer {
  margin-top: .5em;
  margin-bottom: .5em;
}